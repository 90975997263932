const PrivacyPolicy = [
    {
        key: 1,
        privacyHeading: "Introduction",
        privacyContant: "We have developed this Privacy Policy to provide you with the information you need to know about how we collect and handle information, and outline your responsibilities to help you keep yourself safe using our products. Just a quick note: when we talk about “Services” we mean any and all of our websites, products, and services.",
        // privacyContantSpan: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
        key: 2,
        privacyHeading: "Information We Hold and How We Use It",
        privacyContant: "We hold two kinds of information from our users to deliver Services: Secure Data, and Service Data. We treat both Secure Data and Service Data with the same high level of security and assurance, and both kinds of data are always confidential.",
        privacyContantSpan: [
            "Secure Data is data that we cannot access under any circumstance, and includes any information you store in your SafexVault App. This data is encrypted using secure cryptographic keys that exist only in the possession and under the control of our customers. We have no way of accessing or providing decrypted Secure Data and we never receive copies of unencrypted Secure Data. Secure Data is your property, in which we claim no rights, title, or interest beyond that necessary to deliver Services to you. You may add, modify, and delete Secure Data at your discretion.",
            "In some cases we may collect Diagnostic Reports and other troubleshooting, bug, and crash reporting information from customers to help determine problems with Services. If you elect to use beta software, some troubleshooting, bug, and crash reporting data may automatically be transmitted to us. You may decide not to use beta software at your discretion. This data may contain sensitive and personally identifying information, but will never contain Secure Data. Copies of Diagnostic Reports and other information transmitted to us become our property which we may use to continually enhance and improve our Services. We consider Diagnostic Reports, bug, and crash reporting data, and all other troubleshooting information to be Service Data.",
            "We may use your contact information to communicate with you about Service activity, provide support, and send you other information such as product updates and announcements. You may choose to stop receiving communications from us, except certain important notifications such as billing and account security alerts."
        ]
    },    
    {
        key: 3,
        privacyHeading: "Your Responsibilities for Protecting Your Data",
        privacyContant: "When you setup SafexVault App in your device then please make sure to remember Master Password and choose strong Master Password which would be 4 or 6 digits number to ensure that it cannot guess by anyone easily. We request you to do not use numbers in sequence such as 1 2 3 4 5 6.",
        privacyContantSpan:"In case of loss of Master Password, we will not be able to help you to retrieve your data as we are not storing anything on server or collecting any information from you. We request you to keep safe your master password & remember always.",
    },
    {
        key: 4,
        privacyHeading: "Disclosure",
        privacyContant: "We have both policy and technological protections to prevent the unauthorized disclosure of information. However we do reserve the right to disclose any information we have if compelled to do so by a court of law with competent jurisdiction, and in such an event we will hand over requested Secure Data and Service Data. If permitted by the order issued by the court, we will notify you of such a request and whether or not we have complied. However your Secure Data should remain private as long as you keep to your Responsibilities for Protecting Your Data as outlined above. If we have a reasonable and good faith belief that a disclosure is necessary to prevent criminal activity or damage to life or property, we may disclose Service Data to the authorities.",
        privacyContantSpan: [
            "In the case of SafexVault Families and Teams, we may provide Administrator, Owner, or Organizer contact information to verified members of a SafexVault Team or Family. In such cases we may also communicate Service Data specific to particular requests between users and Administrators, Owners, and Organizers.",
            "We have never and will never sell customer information. We use your Secure Data for no purpose other delivering it to your devices."
        ]
    },
    {
        key: 5,
        privacyHeading: "Updates to our Privacy Policy",
        privacyContant: "At our discretion we may make changes to this Policy and note the date of the last revision. You should check here frequently if you need to know of updates to our Privacy Policy. Previous versions will be made available from this page."
    },
    {
        key: 6,
        privacyHeading: "Contact Us",
        privacyContant: `If you have any questions about this Policy, You can contact us on 
        <a href="mailto:support@safexvault.com" style="color: blue; text-decoration: underline;">
            support@safexvault.com
        </a> 
        and our team will do their best to get back to you as soon as possible about any of your questions.`
    },
]

export default PrivacyPolicy;