const FaqQua = [
  {
    key: 1,
    eventkey: "0",
    faqheading: "What is SafexVault?",
    faqContant:
      "SafexVault is a secure app that helps you organize and store important information in categorized templates, protected with a master password and strong encryption.",
  },
  {
    key: 2,
    eventkey: "1",
    faqheading: "How does SafexVault protect my data?",
    faqContant:
      "Your data is secured with AES-128 encryption and can only be accessed with your Master Password or Apple Touch ID.",
  },
  {
    key: 3,
    eventkey: "2",
    faqheading: "Do I need to create an account to use SafexVault?",
    faqContant:
      "No, SafexVault does not require login or registration. Your data is stored securely on your device.",
  },
  {
    key: 4,
    eventkey: "3",
    faqheading: "What types of information can I store in SafexVault?",
    faqContant: `
      You can store various types of information, including:<br />
      • Credit/Debit cards<br />
      • Bank details<br />
      • Emails & Logins<br />
      • Insurance & Memberships<br />
      • PC logins<br />
      • ID Cards (Passport, Visa, Driving License, PAN, Aadhaar, Voter ID, etc.)<br />
      • Serial numbers<br />
      • Vehicles information<br />
      • Tax documents<br />
      • Medical prescriptions<br />
      • Addresses & Emergency contacts<br />
      • Notes
    `,
  },

  {
    key: 5,
    eventkey: "4",
    faqheading: "How can I quickly access my stored information?",
    faqContant:
      "You can categorize your data for easy access and retrieve frequently used details directly from the dashboard.",
  },
  {
    key: 6,
    eventkey: "5",
    faqheading: "Can I create custom templates for storing information?",
    faqContant:
      "Yes, you can create dynamic templates with custom fields to store information as per your needs.",
  },
  {
    key: 7,
    eventkey: "6",
    faqheading: "Is my data stored on the cloud?",
    faqContant:
      "No, all your data is stored securely on your device, ensuring complete privacy.",
  },
  {
    key: 8,
    eventkey: "7",
    faqheading: "What happens if I forget my Master Password?",
    faqContant:
      "For security reasons, SafexVault does not store or recover Master Passwords. Make sure to keep it safe.",
  },
  {
    key: 9,
    eventkey: "8",
    faqheading: "Can I use Touch ID for quick access?",
    faqContant:
      "Yes, SafexVault supports Apple Touch ID for quick and secure access.",
  },
  {
    key: 10,
    eventkey: "9",
    faqheading: "What features are available in the premium version?",
    faqContant:
      "The premium version offers: <br /> • Unlimited data storage <br /> • No ads <br /> • Backup & restore functionality",
  },
  {
    key: 11,
    eventkey: "10",
    faqheading: "How can I upgrade to the premium version?",
    faqContant: "You can upgrade to premium via in-app purchase.",
  },
  {
    key: 12,
    eventkey: "11",
    faqheading: "Can I back up and restore my data?",
    faqContant:
      "Yes, premium users can back up and restore their data for added security.",
  },
];

export default FaqQua;
