import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';

function Footer() {
    return (
        <>
            <footer className="main-footer">
                <Container>
                    <div className='footerRow'>
                        <div className="footer-listing">
                            <Nav defaultActiveKey="/home" as="ul" className='d-flex align-items-center justify-content-center'>
                                <Nav.Item as="li">
                                    <Nav.Link href="/">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="link-1" href="/upgrade">Premium Version</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="link-2" href="/privacy-policy">Privacy Policy</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="link-3" href="/faqs">
                                        FAQs
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="link-4" href="/support">
                                        Support
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='mx-auto'>
                            <span className=''>
                                © {new Date().getFullYear()} safeXvault.com, All rights reserved.
                            </span>
                        </div>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer;