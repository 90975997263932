// import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import appStore from "../assets/img/iphone_app_icon_big.png";
import playStore from "../assets/img/anroid_app_icon_big.png";
import mobile from "../assets/img/index-heroSectionMobile-withShadow.png";
import mobile2 from "../assets/img/index-heroSectionMobile.png";
import featureMobile from "../assets/img/home/featureMoblie.png";
import { FeatureInfo, RightFeatureInfo } from "../component/FeatureInfo";
import BenefitsCard from "../component/BenefitsCard";

import slider1 from "../assets/img/home/slider1.png";
import slider2 from "../assets/img/home/slider2.png";
import slider3 from "../assets/img/home/slider3.png";
// import slider4 from "../assets/img/home/slider4.png";
import slider5 from "../assets/img/home/slider5.png";
// import slider6 from "../assets/img/home/slider6.png";

import { Splide, SplideSlide } from '@splidejs/react-splide';

function HomePage() {

    return (
        <>
            <section className="heroSection">
                <Container className="padding-0">
                    <Row className="heroSectionRow align-items-center">
                        <Col lg={8}>
                            <h1 className="heroSection-title">Frequently
                                <br />
                                <span>
                                    Viewed Cards
                                </span>
                            </h1>
                            <span className="heroSection-span">
                                Best secure app to keep your all information safe
                            </span>
                            <div className="heroSection-dwLinkDiv d-flex">
                                <a href="https://apps.apple.com/in/app/safexvault/id1569369836" target="_blank"   rel="noopener noreferrer"  className="heroSection-dwLink">
                                    <img src={appStore} alt="App Store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.safexvault.android" target="_blank"   rel="noopener noreferrer" className="heroSection-dwLink">
                                    <img src={playStore} alt="Google Play Store" />
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} className="">
                        </Col>
                        <div className="heroSection-mobileDiv">
                            <div className="heroSection-mobile">
                                <img src={mobile} alt="" className="img-fluid desktop-mobile" />
                                <img src={mobile2} alt="" className="img-fluid mobile-img" />
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="features-section commonSection-padding">
                <Container>
                    <div className="sectionHeading">
                        <h5>Features</h5>
                        <p>Do you keep forgetting your important personal information & passwords? With SafexVault App - Keep all your Passwords & Personal information securely at just one place.</p>
                    </div>
                    <Row>
                        <Col lg="4">
                            <FeatureInfo />
                        </Col>
                        <Col lg="4">
                            <Col className="features-sectionMobile-div">
                                <img src={featureMobile} className="img-fluid" alt="" />
                            </Col>
                        </Col>
                        <Col lg="4">
                            <RightFeatureInfo />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="benefits-section commonSection-padding">
                <Container>
                    <div className="sectionHeading">
                        <h5>BENEFITS</h5>
                        <p>SafexVault securely stores your important information with AES-128 encryption, protected by a Master Password or Touch ID. Easily organize data like cards, logins, IDs, and more with custom templates for quick access. No login required, and premium features offer unlimited storage, no ads, and backup/restore options. Stay safe, organized, and in control with SafexVault!</p>
                    </div>
                    <BenefitsCard />
                </Container>
            </section>

            <section className="screen-section commonSection-padding">
                <Container>
                    <div className="sectionHeading">
                        <h5>Screen</h5>
                        <p>Explore SafexVault’s intuitive and secure interface with app screenshots.</p>
                    </div>
                    <div className="screen-sliderDiv">
                        <Splide aria-label="My Favorite Images"
                            options={{
                                rewind: false,
                                perPage: 3,
                                gap: '1.5rem',
                                type: 'loop',
                                focus: "center",
                                pagination: false,
                                autoplay: false,
                                breakpoints: {
                                    1600: {
                                        perPage: 3,
                                    },
                                    // 1200: {
                                    //     perPage: 2,
                                    // },
                                    // 900: {
                                    //     perPage: 2,
                                    // },
                                    640: {
                                        perPage: 1,
                                        focus: "center",
                                    },
                                },
                            }}
                        >

                            <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider1} alt="screen-sliderImage1" />
                            </SplideSlide>
                            <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider2} alt="screen-sliderImage2" />
                            </SplideSlide>
                            <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider3} alt="screen-sliderImage3" />
                            </SplideSlide>
                            {/* <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider4} alt="Image 2" />
                            </SplideSlide> */}
                            <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider5} alt="screen-sliderImage4" />
                            </SplideSlide>
                            {/* <SplideSlide className="screen-slider-imgDiv">
                                <img src={slider6} alt="Image 2" />
                            </SplideSlide> */}
                        </Splide>
                    </div>
                </Container>
            </section>

            <section className="reviewsSection commonSection-padding">
                <Container>
                    <div className="sectionHeading">
                        <h5 className="reviews-margin">Customer Reviews</h5>
                    </div>
                    <div className="reviewsSection-slider">
                        <Splide aria-label="card-review-div"
                            options={{
                                rewind: false,
                                perPage: 1,
                                gap: '1.5rem',
                                type: 'loop',
                                focus: "center",
                                autoplay: true,
                                pauseOnHover: true,
                                arrows: false,
                            }}
                        >
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            After long search and using many other apps, I found this is best app ever, it has satisfied my all needs. It's just my wallet and I don't need carry my all cards with me. No ads, very simple layouts and easy to use.
                                        </Card.Text>
                                        <h6>
                                            Bhadreshwar
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <h6>
                                        krunal dodia
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            After long search and using many other apps, I found this is best app ever, it has satisfied my all needs. It's just my wallet and I don't need carry my all cards with me. No ads, very simple layouts and easy to use.
                                        </Card.Text>
                                        <h6>
                                        Mpagi Frank
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <h6>
                                          John Deo
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            After long search and using many other apps, I found this is best app ever, it has satisfied my all needs. It's just my wallet and I don't need carry my all cards with me. No ads, very simple layouts and easy to use.
                                        </Card.Text>
                                        <h6>
                                            Barb Akew
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                            <SplideSlide className="card-review">
                                <Card className="reviewCard h-100">
                                    <Card.Body>
                                        <Card.Text className="reviewCard-contant">
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <h6>
                                        Anita Bath
                                        </h6>
                                    </Card.Body>
                                </Card>
                            </SplideSlide>
                        </Splide>

                    </div>
                </Container>
            </section>

            <section className="dw-link-section">
                <Container>
                    <Row className="align-items-center justify-content-center ">
                        <Col lg={1} md={0}></Col>
                        <Col lg={4} md={6}>
                            <div className="dw-mobileDiv">
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className="dw-contantDiv">
                                <h5>Get Your Free Download Of SafeXvault App</h5>
                                <p>Select your device platform and get download started</p>
                                <div className="heroSection-dwLinkDiv d-flex">
                                    {/* <a href="javascript:;" className="heroSection-dwLink">
                                        <img src={appStore} alt="App Store" />
                                    </a>
                                    <a href="javascript:;" className="heroSection-dwLink">
                                        <img src={playStore} alt="Google Play Store" />
                                    </a> */}
                                     <a href="https://apps.apple.com/in/app/safexvault/id1569369836" target="_blank"   rel="noopener noreferrer"  className="heroSection-dwLink">
                                    <img src={appStore} alt="App Store" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.safexvault.android" target="_blank"   rel="noopener noreferrer"  className="heroSection-dwLink">
                                    <img src={playStore} alt="Google Play Store" />
                                </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={1} md={0}></Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default HomePage;