import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import callImg from "../assets/img/call.png";
import mailImg from "../assets/img/mail.png";
import locationImg from "../assets/img/location.png";
import toast from "react-hot-toast";
import { useState } from "react";

function Support() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const [isLoader, setIsLoader] = useState(false);

  // Handle Input Change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate Form
  const validate = () => {
    let error = {};
    let isValid = true;

    if (!formData.name) {
      isValid = false;
      error["name"] = "Please Enter Name";
    }

    if (!formData.email) {
      isValid = false;
      error["email"] = "Please Enter Email";
    } else {
      let pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!pattern.test(formData.email)) {
        isValid = false;
        error["email"] = "Please Enter Valid Email";
      }
    }

    if (!formData.subject) {
      isValid = false;
      error["subject"] = "Please Enter Subject";
    }

    if (!formData.message) {
      isValid = false;
      error["message"] = "Please Enter Message";
    }

    setErrors(error);
    return isValid;
  };

  // Submit Form
  const submitFormDetail = (e) => {
    e.preventDefault();
    setIsLoader(true);

    if (validate()) {
      emailjs
        .send(
          "service_t3wznkt", // Your EmailJS service ID
          "template_dyf2swh", // Your EmailJS template ID
          formData,
          "8mucImbha5auoj6O1" // Your public key
        )
        .then(
          () => {
            toast.success("Form Submitted Successfully");
            setFormData({
              name: "",
              email: "",
              subject: "",
              message: "",
            }); // Reset form fields
            setIsLoader(false);
          },
          (error) => {
            toast.error("Failed to send message. Please try again.");
            console.error("EmailJS Error:", error);
            setIsLoader(false);
          }
        );
    }
  };

  return (
    <>
      <section className="innerPage-heroSection">
        <Container>
          <div className="innerPage-heroSectionInfo">
            <h1>Support</h1>
            <Breadcrumb className="innerPage-breadkrumb">
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Support</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Container>
      </section>

      <section className="support-formsection">
        <Container>
          <div className="support-formDiscription">
            <h5>Get In Touch</h5>
            <p>
              Have questions or need assistance? We're here to help! Fill out
              the form below, and our support team will get back to you as soon
              as possible. Your security and convenience are our priority!
            </p>
          </div>
          <div className="support-formDiv">
            <Form className="support-form" onSubmit={submitFormDetail}>
              <Row>
                <Col md="4">
                  <Form.Group
                    className="supportForm-inputDiv"
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    className="supportForm-inputDiv"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    className="supportForm-inputDiv"
                    controlId="formBasicsubject"
                  >
                    <Form.Control
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                    {errors.subject && (
                      <p className="text-danger">{errors.subject}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="supportForm-inputDiv"
                controlId="formBasicmessage"
              >
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  rows={8}
                />
                {errors.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </Form.Group>
              <Button className="submitBtn" type="submit" disabled={isLoader}>
                {isLoader ? "Loading..." : "Submit"}
              </Button>
            </Form>
          </div>
        </Container>
      </section>

      <section className="support-locationDiv">
        <Container>
          <div className="support-locationinfoDiv">
            <h2>NexusLink Services</h2>
            <p>
              Address: 407, Maruti Plaza, Fourth Floor, Nr. Vijay Park BRTS,
              Highway No.8, Krishna Nagar, Naroda, Ahmedabad-382350, Gujarat,
              India
            </p>
          </div>
          <Row className="support-otherInfoDiv">
            <Col lg="4">
              <div className="mb-3 support-otherInfo">
                <div className="support-otherImg">
                  <img src={callImg} className="img-fluid" alt="" />
                </div>
                <span>+91 7984465841</span>
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3 support-otherInfo">
                <div className="support-otherImg">
                  <img src={mailImg} className="img-fluid" alt="" />
                </div>
                <span>support@safexvault.com</span>
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3 support-otherInfo">
                <div className="support-otherImg">
                  <img src={locationImg} className="img-fluid" alt="" />
                </div>
                <a
                  href="https://nexuslinkservices.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>www.nexuslinkservices.com</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Support;
