import { Breadcrumb, Col, Container, Row } from "react-bootstrap";

import checkBox from "../assets/img/checkBox.png";
import mobile from "../assets/img/primiumVerson-mobile.png";
import PremiumVersionInfo from "../component/PremiumVersionInfo";


function PremiumVersion() {
    return (
        <>
            <section className="innerPage-heroSection">
                <Container>
                    <div className="innerPage-heroSectionInfo">
                        <h1>Premium Version</h1>
                        <Breadcrumb className="innerPage-breadkrumb">
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Premium Version</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Container>
            </section>

            <section className="premiumVersion-benefitsSection">
                <Container>
                    <div className="premiumVersion-benefitsDiv">
                        <h2>Lifetime $3.5</h2>
                        <h6>Add Unlimited Reminders For</h6>
                        <Row className="premiumVersion-benefitsBoxRow">
                            <Col lg="3" sm="6">
                                <div className="premiumVersion-benefitsBox">
                                    <img src={checkBox} alt="" />
                                    <span>Bank Account</span>
                                </div>
                            </Col>
                            <Col lg="3" sm="6">
                                <div className="premiumVersion-benefitsBox">
                                    <img src={checkBox} alt="" />
                                    <span>Credit Card</span>
                                </div>
                            </Col>
                            <Col lg="3" sm="6">
                                <div className="premiumVersion-benefitsBox">
                                    <img src={checkBox} alt="" />
                                    <span>Email Account</span>
                                </div>
                            </Col>
                            <Col lg="3" sm="6">
                                <div className="premiumVersion-benefitsBox">
                                    <img src={checkBox} alt="" />
                                    <span>Web Logins</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="premiumVersion-infoSection">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="8">
                            <div className="main-premiumVersion-infoDiv">

                                {PremiumVersionInfo.map(obj => {
                                    return (
                                        <>
                                            <div className="premiumVersion-infoDiv">
                                                <div className="premiumVersion-infoDivIcon">
                                                    <img src={obj.PremiumVersionInfoImg} alt="" />
                                                </div>
                                                <div className="premiumVersion-info">
                                                    <h5>
                                                        <span>{obj.PremiumVersionInfoHeadingSpan}</span>
                                                        {obj.PremiumVersionInfoHeading}</h5>
                                                    <p>
                                                        {obj.PremiumVersionInfoContant}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="premiumVersion-infoImg">
                                <img src={mobile} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default PremiumVersion;