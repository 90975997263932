import { Card, Row, Col } from "react-bootstrap";

import fetureImg1 from "../assets/icon/feature1.png";
import fetureImg2 from "../assets/icon/feature2.png";
import fetureImg3 from "../assets/icon/feature3.png";
import fetureImg4 from "../assets/icon/feature4.png";
import fetureImg5 from "../assets/icon/feature5.png";
import fetureImg6 from "../assets/icon/feature6.png";

export const FeatureInfo = () => {
    const LeftData = [
        {
            key: 1,
            img: fetureImg1,
            alt: "Feature img 1",
            title: "ONE",
            content: "SafexVault Organize your information securely in category wise to find it easy & quick"
        },
        {
            key: 2,
            img: fetureImg2,
            alt: "Feature img 2",
            title: "Tow",
            content: "All your important information is protected with Master Password so no one can access without it"
        },
        {
            key: 3,
            img: fetureImg3,
            alt: "Feature img 3",
            title: "three",
            content: "SafexVault has user friendly User Interface & easy to use with quick access using Apple Touch Id Feature"
        },
    ]

    return (
        <>
            {
                LeftData.map(obj => {
                    return (
                        <>
                            <Card className="feature-card leftFeature-card">
                                <Row>
                                    <Col xs={8} className="feature-contantDiv">
                                        <h1 className="feature-contantHeading">
                                            Feature
                                            <span>
                                                {obj.title}
                                            </span>
                                        </h1>
                                        <p>
                                            {obj.content}
                                        </p>
                                    </Col>
                                    <Col xs={4} className="feature-imgDiv">
                                        <img src={obj.img} alt={obj.alt} />
                                    </Col>
                                </Row>
                            </Card>
                        </>
                    )
                }
                )
            }
        </>
    )
}


export const RightFeatureInfo = () => {

    const RightData = [
        {
            key: 1,
            img: fetureImg4,
            alt: "Feature img 4",
            title: "Four",
            content: "Create dynamic templates by adding all required fields which will help you to store all information as you needed."
        },
        {
            key: 2,
            img: fetureImg5,
            alt: "Feature img 5",
            title: "Five",
            content: "Quick access to your all Credit/Debit cards from Dashboard of App"
        },
        {
            key: 3,
            img: fetureImg6,
            alt: "Feature img 6",
            title: "Six",
            content: "Store your passwords using strong encryption algorithm AES-128 with strongest key to keep away from hackers."
        },
    ]

    return (
        <>
            {
                RightData.map(obj => {
                    return (
                        <>
                            <Card className="feature-card rightFeature-card">
                                <Row>
                                    <Col xs={8} className="feature-contantDiv">
                                        <h1 className="feature-contantHeading">
                                            Feature
                                            <span>
                                                {obj.title}
                                            </span>
                                        </h1>
                                        <p>
                                            {obj.content}
                                        </p>
                                    </Col>
                                    <Col xs={4} className="feature-imgDiv">
                                        <img src={obj.img} alt={obj.alt} />
                                    </Col>
                                </Row>
                            </Card>
                        </>
                    )
                }
                )
            }
        </>
    )
}


